import React from 'react';
import classes from '../pages/classes';

function ClassCard(props) {
    return(
        <div>
            <p className = 'cardHeader'>{props.name}</p>
            <p className = 'cardBody'>{props.body}</p>
        </div>
    );
}


function SemesterCard(props) {
    return (
        <div className = 'semContainer'>
            <p className = 'semHeader'>{classes[props.semester]["header"]}</p>
            <hr />
            <div className = 'semClasses'>
            {classes[props.semester]["classes"].map( (current, index) => {
                return (
                    <div className = 'classCard' key = {index}>
                        <ClassCard name = {current} body = {classes["descriptions"][current]}/>
                    </div>
                )})
            }
            </div>
        </div>
    );
}


export default SemesterCard;