import React from 'react';
import './Pages.css';
import SemesterCard from '../Components/SemesterCard.js';
import './Education.css';

function Education() {
  return (
    <div className = 'page'>
        <div className = 'eduHeader'>
            <p className = 'headerText'>Class List</p>
        </div>
            <div className = 'eduBody'>
                <div className = 'semsContainer'>
                  <section id = 'fall20'> <SemesterCard semester = 'fall20' /> </section>
                  <section id = 'spring21'> <SemesterCard semester = 'spring21' /> </section>
                  <section id = 'fall21'> <SemesterCard semester = 'fall21' /> </section>
                  <section id = 'spring22'> <SemesterCard semester = 'spring22' /> </section>
                  <section id = 'fall22'> <SemesterCard semester = 'fall22' /> </section>
                </div>
                <ul className = 'eduNav'>
                    <p className = 'eduNavHeader'>Jump to:</p>
                    <li> <a className = 'eduNavLink' href = '#fall20'>Fall 2020</a> </li>
                    <li> <a className = 'eduNavLink' href = '#spring21'>Spring 2021</a> </li>
                    <li> <a className = 'eduNavLink' href = '#fall21'>Fall 2021</a> </li>
                    <li> <a className = 'eduNavLink' href = '#spring22'>Spring 2022</a> </li>
                    <li> <a className = 'eduNavLink' href = '#fall22'>Spring 2022</a> </li>
                </ul>
            </div>
    </div>
  );
}
export default Education;