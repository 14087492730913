import React from 'react';
import resume from '../pages/Resume 1:20:23.pdf';
import './Footer.css'

function Footer() {
    return (
        <div className = 'footer'>
            <p>This website was created by Brady Carden</p>
            <p>Download a PDF version of my resume here: <a href = {resume} download>Brady Carden's resume</a></p>
        </div>
    );
}

export default Footer;