import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
background: #38B0FF;
display: flex;
justify-content: space-between;
height: 10rem;
`;

export const NavLink = styled(Link)`
color: #434242;
display: flex;
align-items: center;
text-decoration: none;
padding: .2rem 1rem;
height: 100%;
cursor: pointer;
&:hover {
    text-decoration: underline;
    border-bottom: .2rem solid aliceblue;
    padding: 0rem 1rem;
}
&.active {
	color: white;
    text-decoration: none;
    font-style: italic;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: end;
margin-right: 3rem;
@media screen and (max-width: 1380px) {
	display: none;
}
`;



