import React from 'react';
import './Pages.css';
import './Resume.css'
import OU from './OUSeal.png';

function Resume() {
    return (
        <div className='page'>
            <div className='resumeHeader'>
                <img src={OU} alt='University of Oklahoma Seal' className='headerImage'></img>
                <p>Professional Resume</p>
            </div>
            <div className='resumeContainer'>
                <div className='resumeEntry' id='edu'>
                    <h1>Education</h1>
                    <ul>
                        <li>Junior at the University of Oklahoma</li>
                        <li>Expected Graduation Date: May 2025 with M.S. in Computer Science</li>
                        <li>Current GPA: 3.73 (74 hours completed)</li>
                        <li>Exhaustive class list on the Education Tab</li>
                    </ul>
                </div>
                <div className='resumeEntry' id='languages'>
                    <h1>Languages</h1>
                    <p>These are some of the langauges I've worked with:</p>
                    <ul id='langList'>
                        <div id='col1'>
                            <li>C</li>
                            <li>C++</li>
                            <li>Java</li>
                        </div>
                        <div id='col2'>
                            <li>JavaScript</li>
                            <li>TypeScript</li>
                            <li>ReactJS</li>
                        </div>
                        <div id='col3'>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>Swift</li>
                        </div>
                        <div id='col3'>
                            <li>SwiftUI</li>
                            <li>Git</li>
                        </div>
                    </ul>
                </div>
                <div className='resumeEntry' id='experience'>
                    <h1>Coding Projects</h1>
                    <p className='header'>Daily SMS Question</p>
                    <ul className='entryBody'>
                        <li>Sends a daily question from a question bank to a group of users, then records and evaluates the responses.</li>
                        <li>Text messaging was powered by Twilio</li>
                        <li>Designed with 2 other students for professors to quiz students</li>
                        <li>Code Stack: JavaScript, GCP, NodeJS, ExpressJS, NextJS, and mySQL</li>
                    </ul>
                    <p className='header'>About Me Website</p>
                    <ul className='entryBody'>
                        <li>This website! A simple first project in web development, intended as a way to learn the basics of JavaScript, HTML, CSS, and web hosting</li>
                        <li>Code Stack: JavaScript, NodeJS, HTML, CSS, and Firebase (Free tier)</li>
                    </ul>
                </div>
                <div className='resumeEntry' id='work'>
                    <h1>Work Experience</h1>
                    <p className='header noBreak'>Bad Daddy's Burger Bar</p><p className='tag'> - Host and Wait Staff</p>
                    <ul className='entryBody'>
                        <li>September 2022 - Present</li>
                        <li>Emphasis on the value of customer experience and hospitality</li>
                    </ul>
                </div>
                <div className='resumeEntry' id='extra'>
                    <h1>Extra Curriculars</h1>
                    <p className='header'>Santa Clara Vanguard Member</p>
                    <ul className='entryBody'>
                        <li>December 2021 - August 2022</li>
                        <li>Santa Clara Vanguard is a premier and founding member of Drum Corps International</li>
                        <li>Monthly rehearsals December through March leading up to a full summer competitive tour</li>
                    </ul>
                    <p className='header'>Pride of Oklahoma Marching Band Member</p>
                    <ul className='entryBody'>
                        <li>August 2020 - January 2022</li>
                        <li>Member of the Drumline</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default Resume;