import React from 'react';
import './Pages.css';
import './About.css';
import family from './PicFamily.jpg';
import linkedin from './linkedin.png';
import mail from './mail.png';
import handshake  from './handshake.png';

class About extends React.Component {
  render() {
  return (
    <div className = 'page' id = 'about'>
      <section className = 'aboutHeader'>
        <div id = 'headerTag'>
            <p id= 'line1'>Brady Carden</p>
            <p>Junior at the University of Oklahoma</p>
            <p>Gallogly School of Engineering</p>
          </div>
          <div className = 'polaroid'>
              <img id = 'pic' src = {family} alt = 'Me (center) with my parents'/>
              <div className = 'container'>
                  <p id = 'caption' >Me and My Parents</p>
              </div>
          </div>
        </section>
        <section id = 'aboutBody'>
            <div id = 'greet'>
                <p className = 'intro'>Thank you for visiting my site!</p>
                <hr />
                <p id = 'aboutParagraph'>
                    I am entering my sixth semester as a student at the University of Oklahoma and am looking to pursue a software devolpment internship for this summer. I built this site to enter the world of web development and to learn the basics of some of it's most popular tools. This website is powered by ReactJS, HTML, CSS, and is hosted by Netlify. Thanks for checking it out and thanks for considering me for an internship! 
                </p>
            </div>
            <div id = 'contactBody'>
                <p className = 'intro' >Feel free to contact me here:</p>
                <hr />
                <div id = 'contactInfo'>
                <ul id = 'contactList'>
                    <li > <img className = 'contactImg' alt = 'eMail' src = {mail} /></li>
                    <li > <a href = 'https://app.joinhandshake.com/stu/users/27880977' rel="noreferrer"  target = '_blank'> <img title = 'Click to go to Handshake' className = 'contactImg' alt = 'Handshake' src = {handshake} /> </a> </li>
                    <li > <a href = 'https://www.linkedin.com/in/brady-carden-192aab262?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BlmltDENYTzej58S8tv0uxg%3D%3D' rel="noreferrer"  target = '_blank'> <img title = 'Click to go to LinkedIn' className = 'contactImg' alt = 'LinkedIn' src = {linkedin}/> </a></li>
                    <li id = 'email'> Brady.A.Carden-1@ou.edu </li>
                    <li id = 'handshake'> <a title = 'Click to go to Handshake' href = 'https://app.joinhandshake.com/stu/users/27880977' rel="noreferrer"  target = '_blank'>View my Handshake account</a> </li>
                    <li id = 'linkedin'> <a title = 'Click to go to LinkedIn' href = 'https://www.linkedin.com/in/brady-carden-192aab262?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BlmltDENYTzej58S8tv0uxg%3D%3D' rel="noreferrer"  target = '_blank'>View my LinkedIn account</a> </li>
                </ul>
                </div>
            </div>
        </section>
    </div>
  );
}
}
export default About;