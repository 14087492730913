import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './pages/About';
import Resume from './pages/Resume';
import Education from './pages/Education';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import React from 'react';
import './App.css';


function App() {
  return (
    <div className = 'App'>
      <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path='/' exact element = { <About /> } />
            <Route path='/resume' element = { <Resume /> } />
            <Route path='/edu' element = { <Education /> } />
          </Routes>
      </BrowserRouter>
    <Footer />
    </div>
  );
}

export default App;