import React from 'react';
import { Nav, NavLink, NavMenu } from './NavBarElements';
import './Navbar.css';

function Navbar() {
    return (
        <Nav className = 'mainNav'>

    <header className = 'mainHeader'>
        <p id = 'bc' >Brady Carden</p>
        <i id = 'tag' >Excellence in Action... Hopefully</i>
    </header>
    
        <NavMenu>
            <NavLink to = "/" >About</NavLink>
            <NavLink to = "/resume" >Resume</NavLink>
            <NavLink to = "/edu" >Education</NavLink>            
        </NavMenu>
    </Nav>
  );
}
export default Navbar;